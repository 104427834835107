<template>
  <div class="row">
    <div class="col-12">
        <div class="card card-body">
          <h4>طلبات اليوم</h4>
            <img :src="require('@/assets/images/loading.svg')" v-if="loading" style="width: 50px; height; 50px;" alt="">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-sm table-bordered">
                    <thead>
                        <th>
                           # 
                        </th>
                        <th>
                            الاسم
                        </th>
                        <th>
                            الهاتف
                        </th>
                        <th>
                            الوكيل
                        </th>
                        <th>
                            الفندق
                        </th>
                        <th>
                            التاريخ
                        </th>
                        <th>
                            الحالة
                        </th>
                        <th>
                            المبلغ للوكيل
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="order in orders_1" :key="order._id" @click="current = order" v-b-modal.modal-1>
                            <td>
                                {{ order.id }}
                            </td>
                            <td>
                                {{ order.name }}
                            </td>
                            <td>
                                {{ order.phone }}
                            </td>
                            <td>
                                {{ order.reseller.name }}
                            </td>
                            <td>
                                {{ order.hotel.title }}
                            </td>
                            <td>
                                {{ order.date }}
                            </td>
                            <td>
                                <span class="btn btn-sm btn-warning" style="border-radius: 0px;" v-if="order.status == 0">في الانتظار</span>
                                <span class="btn btn-sm btn-success" style="border-radius: 0px;" v-if="order.status == 1">تم التأكيد</span>
                                <span class="btn btn-sm btn-danger" style="border-radius: 0px;" v-if="order.status == 2">في انتظار الالغاء</span>
                                <span class="btn btn-sm btn-secondary" style="border-radius: 0px;" v-if="order.status == 3">ملغي</span>
                                &nbsp;
                                <span class="text-success" v-if="order.cash_done"><i class="fa fa-check"></i></span>
                                <span class="text-primary" v-if="order.cash_calc" style="position: relative; right: -8px"><i class="fa fa-check"></i></span>
                            </td>
                            <td>
                                {{ order.total_reseller }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card card-body">
          <h4>طلبات الغد</h4>
            <img :src="require('@/assets/images/loading.svg')" v-if="loading" style="width: 50px; height; 50px;" alt="">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-sm table-bordered">
                    <thead>
                        <th>
                           # 
                        </th>
                        <th>
                            الاسم
                        </th>
                        <th>
                            الهاتف
                        </th>
                        <th>
                            الوكيل
                        </th>
                        <th>
                            الفندق
                        </th>
                        <th>
                            التاريخ
                        </th>
                        <th>
                            الحالة
                        </th>
                        <th>
                            المبلغ للوكيل
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="order in orders_2" :key="order._id" @click="current = order" v-b-modal.modal-1>
                            <td>
                                {{ order.id }}
                            </td>
                            <td>
                                {{ order.name }}
                            </td>
                            <td>
                                {{ order.phone }}
                            </td>
                            <td>
                                {{ order.reseller.name }}
                            </td>
                            <td>
                                {{ order.hotel.title }}
                            </td>
                            <td>
                                {{ order.date }}
                            </td>
                            <td>
                                <span class="btn btn-sm btn-warning" style="border-radius: 0px;" v-if="order.status == 0">في الانتظار</span>
                                <span class="btn btn-sm btn-success" style="border-radius: 0px;" v-if="order.status == 1">تم التأكيد</span>
                                <span class="btn btn-sm btn-danger" style="border-radius: 0px;" v-if="order.status == 2">في انتظار الالغاء</span>
                                <span class="btn btn-sm btn-secondary" style="border-radius: 0px;" v-if="order.status == 3">ملغي</span>
                                &nbsp;
                                <span class="text-success" v-if="order.cash_done"><i class="fa fa-check"></i></span>
                                <span class="text-primary" v-if="order.cash_calc" style="position: relative; right: -8px"><i class="fa fa-check"></i></span>
                            </td>
                            <td>
                                {{ order.total_reseller }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <b-modal id="modal-1" size="lg" title="تفاصيل الطلب" hide-footer>
        <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered" v-if="current._id">
                <tbody>
                    <tr>
                        <td>
                            ID
                        </td>
                        <td style="padding: 0px">
                            <input type="text" style="border:none; border-radius: 0px" class="form-control" v-model="current.id">   
                        </td>
                    </tr>
                    <tr>
                        <td>
                            الاسم
                        </td>
                        <td style="padding: 0px">
                            <input type="text" style="border:none; border-radius: 0px" class="form-control" v-model="current.name">   
                        </td>
                    </tr>
                    <tr>
                        <td>
                            الهاتف
                        </td>
                        <td style="padding: 0px">
                            <input type="text" style="border:none; border-radius: 0px" class="form-control" v-model="current.phone">   
                        </td>
                    </tr>
                    <tr>
                        <td>
                            الاجمالي
                        </td>
                        <td style="padding: 0px">
                            <input type="number" style="border:none; border-radius: 0px" class="form-control" v-model="current.total">   
                        </td>
                    </tr>
                    <tr>
                        <td>
                            الاجمالي للوكيل
                        </td>
                        <td style="padding: 0px">
                            <input type="number" style="border:none; border-radius: 0px" class="form-control" v-model="current.total_reseller">   
                        </td>
                    </tr>
                    <tr>
                        <td>
                            العمولة
                        </td>
                        <td style="padding: 0px">
                            <input type="number" style="border:none; border-radius: 0px" class="form-control" v-model="current.extra">   
                        </td>
                    </tr>
                    <tr>
                        <td>
                            حالة الطلب
                        </td>
                        <td style="padding: 0px">
                            <select style="border:none; border-radius: 0px" class="form-control" v-model="current.status">
                                <option value="0">
                                    في الانتظار
                                </option>
                                <option value="1">
                                    تم التأكيد
                                </option>
                                <option value="2">
                                    في انتظار الالغاء
                                </option>
                                <option value="3">
                                    ملغي
                                </option>
                            </select>  
                        </td>
                    </tr>
                    <tr v-if="current.status == 3 || current.status == 2">
                        <td>
                            سبب الالغاء
                        </td>
                        <td style="padding: 0px">
                            <input type="text" style="border:none; border-radius: 0px" class="form-control" v-model="current.cancel_reason">   
                        </td>
                    </tr>
                    <tr v-if="current.status == 3 || current.status == 2">
                        <td>
                            خصم من الوكيل (مديونية عليه)
                        </td>
                        <td style="padding: 0px">
                            <input type="number" style="border:none; border-radius: 0px" class="form-control" v-model="current.reseller_dept">   
                        </td>
                    </tr>
                    <tr>
                        <td>
                            رقم تأكيد الطلب
                        </td>
                        <td style="padding: 0px">
                            <input type="text" style="border:none; border-radius: 0px" class="form-control" v-model="current.number">   
                        </td>
                    </tr>
                    <tr>
                        <td>
                            الفترة من <span class="text-danger">* لا تنسى الاسعار يدوياً</span>
                        </td>
                        <td style="padding: 0px">
                            <input type="date" style="border:none; border-radius: 0px" class="form-control" v-model="current.from">   
                        </td>
                    </tr>
                    <tr>
                        <td>
                            الفترة الى <span class="text-danger">* لا تنسى الاسعار يدوياً</span>
                        </td>
                        <td style="padding: 0px">
                            <input type="date" style="border:none; border-radius: 0px" class="form-control" v-model="current.to">   
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-success" @click="saveCurrent()">
                <i class="fa fa-check-square"></i>
                حفط الطلب
            </button>
        </div>
        ...
        <p>
             الاسعار عند انشاء الطلب:
        </p>
        <div class="col-12 table-responsive">
            <table class="table table-sm table-bordered">
                <thead>
                    <th>
                        اليوم
                    </th>
                    <th>
                        التكلفة
                    </th>
                    <th>
                        السعر للوكيل
                    </th>
                </thead>
                <tbody>
                    <tr v-for="(day) in current.days_prices" :key="day.i">
                        <td>
                            {{ days[new Number(day.i)] }}
                        </td>
                        <td>
                            {{ day.price }}
                        </td>
                        <td>
                            {{ day.reseller }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { VBModal, BModal, BFormCheckbox } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    components: {
        BModal,BFormCheckbox,
    },
    data(){
        return {
            jwt: JSON.parse(localStorage.getItem("user")).jwt,
            orders: [],
            loading: false,
            page: 0,
            current: {},
            hotels: [],
            q: null,
            date: null,
            status: window.location.href.includes('canceled') ? 2 : null,
            ori: window.location.href,
            days: [
                "السبت",
                "الاحد",
                "الاثنين",
                "الثلاثاء",
                "الاربعاء",
                "الخميس",
                "الجمعة"
            ],
            cc: null
        }
    },
    beforeDestroy(){
        clearInterval(this.cc)
    },
    created(){
        var g = this;
        g.cc = setInterval(() => {
            if(window.location.href != g.ori){
                g.ori = window.location.href
                g.status = window.location.href.includes('canceled') ? 2 : null;
                g.getOrders();
            }
        });
        this.getOrders();
    },
    methods: {
        getOrders(){
            var g = this;
            g.loading = true;
            $.post(api + '/admin/orders/index', {
                jwt: this.jwt,
                page: this.page,
                date: new Date().toISOString().split("T")[0],
                q: this.q,
                status: this.status
            }).then(function(r){
                g.loading = false;
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.orders_1 = r.response
                }
            }).fail(function(){
                g.loading = false;
                alert("حدث مشكلة في الاتصال")
            })
            var s = new Date();
            s.setDate(s.getDate() + 1)
            $.post(api + '/admin/orders/index', {
                jwt: this.jwt,
                page: this.page,
                date: s.toISOString().split("T")[0],
                q: this.q,
                status: this.status
            }).then(function(r){
                g.loading = false;
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.orders_2 = r.response
                }
            }).fail(function(){
                g.loading = false;
                alert("حدث مشكلة في الاتصال")
            })
        },
        saveCurrent(){
            var g = this;
            g.loading = true;
            $.post(api + '/admin/orders/save', {
                jwt: this.jwt,
                _id: this.current._id,
                order: this.current
            }).then(function(r){
                g.loading = false;
                if(r.status != 100){
                    alert(r.response)
                }else{
                    $("#modal-1___BV_modal_header_ > button").click()
                }
            }).fail(function(){
                g.loading = false;
                alert("حدث مشكلة في الاتصال")
            })
        }
    }
}
</script>

<style>

</style>